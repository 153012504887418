/**
 *
 * =======================================================================
   Colors
 * =======================================================================
 */

//Primary colors. Mostly applied in buttons
$primary-color: #366caf; //blue
$secondary-color: #FF9800; //orange
$special-color: purple; //purple
$accent-color: #3f51b5;

//Institution specific colors. Each institution can customize their colors here.
$institution-primary-color: #00274c;
$institution-secondary-color: #ffcb05;

//Homepage hero section search button background color
$homepage-search-background-color: $institution-primary-color;

//4 steps colors
$step-1-color:$institution-secondary-color;
$step-2-color:$special-color;
$step-3-color:$secondary-color;
$step-4-color:$success-font-color;

//Cookie consent banner style options
$cookie-consent-banner-background-color: $accent-color;
$cookie-consent-banner-font-color: #fff;
$cookie-consent-banner-button-background-color:$institution-secondary-color;
$cookie-consent-banner-button-font-color:$institution-primary-color;
$cookie-consent-banner-link-color:#fff;

//Homepage highlighted searches
$highlighted-searches-background-color: rgba($institution-primary-color, 0.65);
$highlighted-searches-count:15;
$highlighted-searches-images-path: "../images/highlighted-searches";

/**
 *
 * =======================================================================
   IMAGE FILENAMES
 * =======================================================================
 */
$logo-small-filename: "../images/logo-small.png";
$logo-filename: "../images/logo-medium.png";

$department-logo-filename:"../images/MICHR-logo.png";
